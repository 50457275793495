import React, {ReactNode, useRef, useState} from 'react';
import axios from "axios";
import Toaster from "../../components/Toaster";

const PaymentOptions: React.FunctionComponent = () => {
    const [frequency, setFrequency] = useState('month');
    const [price, setPrice] = useState(30);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const handleFrequency = (frequency: string) => {
        setFrequency(frequency)
    }
    const handlePrice = (value: number, isOther: boolean) => {
        setPrice(value);
        setIsOtherSelected(isOther);
    }
    const handleClick = async () => {
        const body: { price: number, redirectUri: string, interval?: string } = {
            price: price,
            interval: frequency !== 'once' ? frequency : undefined,
            redirectUri: `${window.location.href}?state=success`
        };
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/stripe/donation`, body);
            window.location.href = response.data.url
        } catch (error) {
            console.error('Error:', error);
        }
    }
    return <aside
        className='h-full w-[380px] min-w-[380px] bg-white border border-black m-4 p-4 flex flex-col justify-center items-center '>
        <h3 className="text-2xl font-bold pb-2">Donation Amount</h3>
        <div className={'grid grid-rows-3 gap-4 mb-4 w-full'}>
            <div className={'grid grid-cols-3 w-full gap-2 justify-center items-center'}>
                <Button
                    className='p-4'
                    handleClick={() => handleFrequency('year')}
                    selected={frequency === 'year'}>Yearly</Button>
                <Button
                    className='p-4'
                    handleClick={() => handleFrequency('month')}
                    selected={frequency === 'month'}>Monthly</Button>
                <Button
                    className='p-4'
                    handleClick={() => handleFrequency('once')}
                    selected={frequency === 'once'}>Just once</Button>
            </div>
            <div className={'grid grid-cols-3 w-full gap-2 justify-center items-center'}>
                <Button
                    className='p-4'
                    handleClick={() => handlePrice(10, false)}
                    selected={price === 10 && !isOtherSelected}>10 $</Button>
                <Button
                    className='p-4'
                    handleClick={() => handlePrice(30, false)}
                    selected={price === 30 && !isOtherSelected}>30 $</Button>
                <Button
                    className='p-4'
                    handleClick={() => handlePrice(50, false)}
                    selected={price === 50 && !isOtherSelected}>50 $</Button>
            </div>
            <div className={'grid grid-cols-3 w-full gap-2 justify-center items-center'}>
                <Button
                    className='p-4'
                    handleClick={() => handlePrice(20, false)}
                    selected={price === 20 && !isOtherSelected}>20 $</Button>
                <Button
                    className='p-4'
                    handleClick={() => handlePrice(40, false)}
                    selected={price === 40 && !isOtherSelected}>40 $</Button>
                <Button
                    className='p-2 flex flex-col justify-center items-center '
                    handleClick={() => handlePrice(parseInt(inputRef.current?.value ?? '10'), true)}
                    selected={isOtherSelected}> Other
                    <input ref={inputRef} className=' w-10 border border-black bg-white text-black' onChange={(e) => {
                        if (isOtherSelected) {
                            setPrice(parseInt(e.target.value))
                        }
                    }} type={'text'}/></Button>
            </div>
            <button
                className='bg-blue-900 hover:bg-blue-700 active:bg-blue-800 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 text-white font-bold py-2 px-4  border border-black'
                onClick={handleClick}>Pay
            </button>
        </div>
        <div
            className="p-2 flex  items-center gap-4 justify-between">
            OR
        </div>
        <div className="w-full mb-2">
            <h3 className="text-lg text-gray-800 font-semibold mb-2">You can donate with crypto</h3>
            <AddressButton chain={'BASE'} address={'0x2a5890cD26174218063856C8C25B5aF98B3F4718'}/>
            <AddressButton chain={'Optimism'} address={'0x2c3F1Daa0bf085c6c1ed61b03E9C44B779485dd0'}/>
            <AddressButton chain={'Arbitrum One'} address={'0x64449C5631f97A7D211385c1bD87345287dd659a'}/>
            <AddressButton chain={'Polygon'} address={'0xF5632f509B9cfBA4374B236dCBA7EB61C0D6a049'}/>
            <AddressButton chain={'Ethereum'} address={'0x621479e592d20901603F9aec900D02bB5eE382CF'}/>
            <AddressButton chain={'Bitcoin'} address={'bc1q3qtxqlcmpq6kd60624v2f4zragm3st0lg6zkra'}/>
            <AddressButton chain={'Stacks'} address={'SP1QQN4BGDX056FGS1GXA9D87F5QMT6HMG3NSKJA'}/>
        </div>
        <div>
            <hr className="border border-gray-400 my-2"/>
            <h3 className="text-lg text-gray-800 font-semibold mb-2">Where your support goes</h3>
            <p className='mb-2 text-sm'><strong>Technology</strong>: Infrastructure, bandwidth, support, development.
                Collab.Land is the #1
                crypto-native
                community tool and your support means we can stay there.</p>

            <p className='mb-2 text-sm'><strong>People and Projects</strong>: We have 12 staff and contractors to
                support all
                53k communities.
                This
                means your
                support will have real impact on our community-forward team!</p>

            <p className='mb-2 text-sm'><strong>For the Culture</strong>: We have produced a series of short
                documentaries about
                crypto
                communities
                and would
                love to make more! There are so many stories still left to tell.</p>
        </div>
    </aside
    >
};

const Button = ({className, handleClick, selected, children}: {
    className?: string,
    handleClick: () => void,
    selected: boolean,
    children: ReactNode
}) => {
    return <div
        className={`${className} border h-14 border-black cursor-pointer text-center  ${selected ? 'bg-slate-700 text-white hover:bg-slate-600' : 'hover:bg-white bg-gray-100 text-black'}`}
        onClick={handleClick}>
        {children}
    </div>
}
const AddressButton = ({chain, address,}: {
    chain: string
    address: string,
}) => {
    const [showToaster, setShowToaster] = useState(false);

    const showToast = () => {
        setShowToaster(true);
    }

    const onClick = async () => {
        await navigator.clipboard.writeText(address);
        showToast();
    }
    return <div className="flex items-center rounded-full">
        <Toaster
            message="Copied!"
            show={showToaster}
            setShow={setShowToaster}
        />
        <div className="flex-shrink-0">
            <span className="font-bold text-sm">{chain}</span>
        </div>

        <div className="flex-grow">
            <input type="text" className="w-full bg-transparent border-none text-sm px-2"
                   value={address} readOnly/>
        </div>

        <div className="flex-shrink-0">
            <button className="text-white font-bold rounded-full"
                    onClick={onClick}>
                <svg className="h-5 w-5 text-slate-500 hover:text-slate-800" width="24" height="24" viewBox="0 0 24 24"
                     strokeWidth="2"
                     stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <rect x="8" y="8" width="12" height="12" rx="2"/>
                    <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"/>
                </svg>
            </button>
        </div>
    </div>
}

export default PaymentOptions;
