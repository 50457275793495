import React, {useEffect} from 'react';

interface ToasterProps {
    message: string;
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Toaster: React.FC<ToasterProps> = ({message, show, setShow}) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                setShow(false);
            }, 1000);

            return () => clearTimeout(timer);
        }
    }, [show, setShow]);

    return (
        <div
            className={`fixed top-4 right-4 p-4 bg-blue-500 text-white rounded shadow-md transition-opacity duration-300 ${
                show ? 'opacity-100' : 'opacity-0 pointer-events-none'
            }`}
        >
            {message}
        </div>
    );
};

export default Toaster;
